import React from "react"
import { Trans } from 'gatsby-plugin-react-i18next'
import Layout from "../components/layout"
import SEO from "../components/seo"

const DownloadsPage = () => {

  return (
  <Layout>
    <SEO title="Holger Dieffendahl" />
    <h1><Trans>ProduktName</Trans></h1>
    <p><Trans>ProduktText</Trans></p>
    <div style={{
      width: '100%',
      height: 'auto',
      display: 'flex',
      flexDirection: 'row',
      flexWrap: 'wrap',
      justifyContent: 'space-around',
      alignItems: 'center',
      alignContent: 'flex-end',
      padding: '50px 0px 50px 0px'
    }}>
      <a href="/HD_300_C_flyer.pdf" >
        <Trans>ProduktFlyer</Trans>
        <br/>
        <img style={{width: '300px'}} alt="ProduktFlyer" src="/HD_300_C_flyer.png" />
      </a>

      <a href="/HD_300_C_manual.pdf" >
        <Trans>ProduktManual</Trans>
        <br/>
        <img style={{width: '300px'}} alt="ProduktManual" src="/HD_300_C_manual.png" />

      </a>
    </div>
  </Layout>
)
}

export default DownloadsPage
